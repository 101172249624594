<template>
  <div>
    <feather-icon
      v-b-tooltip.hover.lefttop.v-primary
      title="Editar custos de projeto"
      icon="EditIcon"
      size="15"
      @click="$emit('openModal')"
    />
    <!-- <feather-icon
      v-b-tooltip.hover.lefttop.v-primary
      title="Excluir custo de projeto"
      icon="DeleteIcon"
      size="15"
      class="ml-1"
      @click="$emit('openModaDelete')"
    /> -->
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
export default {
  name: 'actions-projectCosts',
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    item: { type: Object, default: () => {} },
  },
};
</script>
